import { Container, Row, Col } from 'react-bootstrap';
import Person from "../../utils/Person";

function PersonSection() {
  return (
    <section className="mb-5 mt-5" id="membres">
        <Container>
            <Row>
                <Col>
    				<Person 
						title='President' 
						img='/images/president.jpeg'
						text='M. BRIMAUD Denis'
					/>
                </Col>
                <Col>
    				<Person 
						title='Secretaire' 
						img='/images/secretaire.jpeg'
						text='Mme BRIMAUD Nathalie'
					/>
                </Col>
                <Col>
    				<Person 
						title='Trésorière' 
						img='/images/tresoriere.jpeg'
						text='Mme SERRES Carole'
					/>
                </Col>
            </Row>
        </Container>
    </section>
  );
}

export default PersonSection;