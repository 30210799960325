import { Container, Row, Col } from "react-bootstrap";

const style = {
  letterSpacing: "-.05rem",
  marginTop: "4rem",
  fontSize: "50px",
};

const But = () => {
  return (
    <section className="mb-3 mt-4 mb-lg-5" id="mission">
      <Container>
        <Row>
          <Col md={5} style={{ margin: "auto" }}>
            <div className="cropped-image"></div>
          </Col>
          <Col md={7}>
            <h2 className=" fw-normal lh-1" style={style}>
              Quel est le <span className="text-muted">but</span> de cette
              association
            </h2>
            <p className="lead">
              L’association a pour objet d’organiser, de promouvoir, de
              développer les activités physiques et sportives de compétition et
              ou de loisir pour les personnes présentant une déficience motrice
              ou sensorielle, ainsi que pour les personnes valides et tous âges.
              Cette association est affiliée à la fédération Française
              Handisport. <br />
              L'affiliation à la fédération Française de basketball se fera plus
              tard en fonction des besoins.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default But;
