import { Container, Row, Col } from "react-bootstrap";

const style = {
  letterSpacing: "-.05rem",
  fontSize: "50px",
};

const Communication = () => {
  return (
    <section className="mb-3 mt-4 mb-lg-5" id="mission">
      <Container>
        <Row>
          <Col md={12}>
            <h2 className=" fw-normal lh-1" style={style}>
              Communication de l'association
            </h2>
              <figure className="lead mt-4">
                <cite className="text-muted">
                  <q>
                    Bonjour à tous,
                    <br/>j'ai le plaisir de vous annoncer l'ouverture des entrainements le samedi 13 janvier a partir de 13h30 pour les 6/10 ans suivi des 11/16 ans à partir de 15h et enfin 18 ans et plus à partir de  16h30 jusqu'à 18h et plus si besoin.
                    <br/>Il a fallu toute la ténacité du CA pour aboutir et je remercie tous ceux qui on participer à l'élaboration de ce projet.
                    <br/>Nous serons accompagné de Stéphane entraineur pro pour l'ouverture de notre activité, et je tenais à le remercier pour son aide.
                    <br/>Vive le sport, vive le basket.
                    <br/>Soyez au rdv samedi à partir de 13h30
                    <br/>A tres bientot
                  </q>
                </cite>
              </figure>
              <p className="fw-bolder lead text-end">Denis Brimaud</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Communication;
