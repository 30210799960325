import { Facebook } from "@styled-icons/bootstrap";

const Footer = () => (
  <>
    <footer className="container d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
      <div className="col-xs-4 d-flex align-items-center">
        <span className="mb-3 mb-md-0 text-muted">© 2023 </span>
      </div>

      <ul className="nav col-xs-4 justify-content-center list-unstyled d-flex">
        <li className="ms-3">
          <a className="text-muted" href="/legal">
            Mentions Légales
          </a>
        </li>
      </ul>

      <ul className="nav col-xs-4 justify-content-end list-unstyled d-flex">
        <li className="ms-3">
          <a
            className="text-muted"
            href="https://www.facebook.com/groups/909002370370770"
            target="_blank"
            rel="noreferrer"
          >
            <Facebook style={{ height: "35px", color: "#3b5998" }} />
          </a>
        </li>
      </ul>
    </footer>
  </>
);

export default Footer;
