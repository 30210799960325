import { Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as NotFound } from '../assets/notfound.svg'; 

export const Notfound = () => {
  return (
    <Container>
      <Row>
        <Col className="text-center">
          <p className="mt-5 text-center fs-1">OOOPS la page demandée n'existe pas !</p>
          <NotFound style={{ maxWidth:"700px", width:"100%", textAlign:"center" }}/>
        </Col>
      </Row>
    </Container>
  );
};

export default Notfound;