import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import {ArrowUpCircle} from '@styled-icons/bootstrap';
import { Button } from 'react-bootstrap';

  
const App = () => {

	let mybutton;

	window.onscroll = function () {
	  mybutton = document.getElementById("btn-back-to-top");
	  scrollFunction(mybutton);
	};
  
	function scrollFunction(mybutton:any) {
	  if (
		document.body.scrollTop > 20 ||
		document.documentElement.scrollTop > 20
	  ) {
		mybutton.style.display = "block";
	  } else {
		mybutton.style.display = "none";
	  }
	}
  
	function backToTop() {
	  document.body.scrollTop = 0;
	  document.documentElement.scrollTop = 0;
	}

	return (
		<>
			<Header />
			<main>
				<Router basename='/'>
					
					<Routes />
				</Router>
				<Footer />
				<Button
					onClick={backToTop}
					id='btn-back-to-top'
					style={{
						position: "fixed",
						bottom: "25px",
						right: "25px",
						display: "none",
						background: "transparent",
						border: "none",
					}}
					className='btn-floating'
					variant="light">
					<ArrowUpCircle style={{ height: "35px", color:"#f19830" }}/>
				</Button>
			</main>
		</>
	);
};
  
export default App;