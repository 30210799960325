import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { Facebook } from "@styled-icons/bootstrap";

const Header = () => {
  const fontStyle = {
    color: "#155263",
    fontWeight: "600",
  };

  return (
    <Navbar
      expand="lg"
      bg="light"
      variant="light"
      style={{ boxShadow: "#b1afaf 0px -3px 10px 0px", padding: "0px" }}
    >
      <Container>
        <Navbar.Brand
          href="/"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Logo style={{ height: "55px", float: "left" }} />
          <h1
            style={{
              fontSize: "13px",
              float: "left",
              fontWeight: "900",
              marginLeft: "15px",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <span style={{ color: "#f19830" }}>BASKET CLUB</span>
            <br />
            <span style={{ color: "#004ba4", letterSpacing: "0.25rem" }}>
              MIREPOIX
            </span>
          </h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="ms-md-4" id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link style={fontStyle} href="/">
              Accueil
            </Nav.Link>
            <Nav.Link style={fontStyle} href="/#mission">
              Mission
            </Nav.Link>
            <Nav.Link style={fontStyle} href="/#membres">
              Membres du bureau
            </Nav.Link>
            <Nav.Link style={fontStyle} href="/#actualite">
              Actualités
            </Nav.Link>
            <Nav.Link style={fontStyle} href="/#location">
              Nous trouver
            </Nav.Link>
            <Nav.Link style={fontStyle} href="/#contact">
              Nous contacter
            </Nav.Link>
            <Nav.Link
              style={fontStyle}
              href="https://www.facebook.com/groups/909002370370770"
              target="_blank"
            >
              <Facebook style={{ height: "25px", color: "#3b5998" }} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
