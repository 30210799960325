export interface PersonProps {
  img: string;
  title: string;
  text: string;
}

const Person = (props: PersonProps) => {
  const { img, title, text } = props;

  return (
    <div className="mt-2 text-center">
      <div style={{ width: "140px", height: "140px", margin: "auto" }}>
        <img
          alt="person"
          className="bd-placeholder-img rounded-circle"
          width="100%"
          height="100%"
          src={img}
          style={{ objectFit: "cover" }}
        />
      </div>
      <h4 className="mt-3 fw-normal">{title}</h4>
      <p>{text}</p>
    </div>
  );
};

export default Person;
