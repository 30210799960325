// import Imagescarousel from "../components/widgets/home/ImagesCarousel";
import PersonSection from "../components/widgets/home/PersonSection";
import But from "../components/widgets/home/But";
import Don from "../components/widgets/home/Don";
// import FacebookIntegration from "../components/widgets/home/FacebookIntegration";
// import ImagesBoard from "../components/widgets/home/ImagesBoard";
import Location from "../components/widgets/home/Location";
import Contact from "../components/widgets/home/Contact";
import { Container, Row, Col } from "react-bootstrap";
import Communication from "../components/widgets/home/Communication";

export const Home = () => (
  <>
    <section className="mb-3 mt-4 mb-lg-5" id="possibilite">
      <Container>
        <Row>
          <Col md={12} style={{ margin: "auto", userSelect: "none" }}>
            <h5
              style={{
                color: "#f19830",
                fontSize: "4.17vw",
                marginBottom: "2.08vw",
                marginLeft: "6.25vw",
              }}
            >
              Où la passion rencontre
            </h5>
            <div
              style={{ background: "#f19830", width: "100%", height: "1.79vw" }}
            ></div>
            <h5
              style={{
                color: "#004ba4",
                fontSize: "6.55vw",
                marginTop: "-5.00vw",
                fontWeight: "900",
                marginLeft: "2.98vw",
              }}
            >
              LA POSSIBILITÉ
            </h5>
          </Col>
        </Row>
      </Container>
    </section>
    <Communication />
    <But />
    <Don />
    <PersonSection />
    {/* <FacebookIntegration /> */}
    {/* <Imagescarousel /> */}
    {/* <ImagesBoard /> */}
    <Location />
    <Contact />
  </>
);

export default Home;
