import { Container, Row, Col } from "react-bootstrap";

const style = {
  letterSpacing: "-.05rem",
  fontSize: "50px",
};

const Location = () => {
  return (
    <section className="mb-5 mt-5" id="location">
      <Container>
        <Row>
          <Col md={7}>
            <h2 className="fw-normal lh-1 mt-md-5" style={style}>
              Nous <span className="text-muted">trouver</span>
            </h2>
            <p className="lead">
              Gymnase de la citée scolaire de Mirepoix
              <br />
              1 route de Limoux
              <br />
              09500 Mirepoix
              <br />
              <span className="fw-bold">
                Entrainements le mardi de 18h à 20h
              </span>
            </p>
            <p className="fs-6 fst-italic fw-lighter">
              <span className="fw-semibold text-muted">
                Communes faisant partie de la communauté de communes :
              </span>
              <br />
              Aigues-Vives • Belloc • Besset • Camon • Cazals-des-Baylès •
              Coutens • Dun • Esclagne • La Bastide-de-Bousignac • La
              Bastide-sur-l'Hers • Lagarde • Lapenne • Le Peyrat • Léran •
              Limbrassac • Malegoude • Manses • Mirepoix • Montbel • Moulin-Neuf
              • Pradettes • Régat • Rieucros • Roumengoux •
              Saint-Félix-de-Tournegat • Saint-Julien-de-Gras-Capou •
              Saint-Quentin-la-Tour • Sainte-Foi • Teilhet • Tourtrol •
              Troye-d'Ariège • Vals • Viviès
            </p>
            <p className="fs-6 fst-italic fw-lighter">
              Club en Ariège (Région Occitanie)
            </p>
          </Col>
          <Col md={5}>
            <iframe
              title="location"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5827.713657798914!2d1.8790320497808908!3d43.086505245092184!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12afabd976f99faf%3A0xafcc855d277cd8ee!2sCit%C3%A9%20scolaire%20de%20Mirepoix!5e0!3m2!1sfr!2sfr!4v1679584523828!5m2!1sfr!2sfr"
              style={{
                border: "0",
                height: "500px",
                width: "100%",
                maxWidth: "500px",
              }}
              loading="lazy"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Location;
