import { Container, Row, Col, Button } from "react-bootstrap";

const style = {
  letterSpacing: "-.05rem",
  marginTop: "4rem",
  fontSize: "50px",
};

const Don = () => {
  return (
    <section className="mb-5" id="don">
      <Container>
        <Row>
          <Col md={7} xs={12}>
            <h2 className="mt-2 fw-normal lh-1" style={style}>
              Soutenez Nos Héros du Terrain!
            </h2>
            <p className="lead">
              Chaque don contribue à notre mission d'encourager et d'inspirer.
              <br />
              Rejoignez-nous dans notre engagement envers le sport inclusif.
            </p>
          </Col>
          <Col
            md={5}
            xs={12}
            className="d-flex justify-content-center align-items-center mt-2"
          >
            <Button
              className="w-50 text-white"
              href="https://www.helloasso.com/associations/basket-club-mirepoix"
              size="lg"
              target="_blank"
            >
              FAIRE UN DON
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Don;
