import { Admin } from "./views/Admin";
import { Home } from "./views/Home";
import { Notfound } from "./views/Notfound";
import { Legal } from "./views/Legal";
import EditionCommunication from "./components/widgets/home/EditionCommunication";
import { useRoutes } from "react-router-dom";

const Routes = () => {
	let routes = useRoutes([
		{ 
			path: "/", 
			element: <Home /> 
		},
		{ 
			path: "/admin", 
			element: <Admin /> 
		}, 
		{ 
			path: "/legal", 
			element: <Legal /> 
		}, 
		// { 
		// 	path: "/edit", 
		// 	element: <EditionCommunication /> 
		// }, 
		{ 
			path: "/*", 
			element: <Notfound /> 
		}, 
	]);
	return routes;
};

export default Routes;